import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import StatusBanner from '../StatusBanner'

import { DataTable, ErrorDisplay, NoElementsMessage } from 'mmfintech-portal-commons'
import {
  TransactionsSectionTitle,
  TransactionsSectionWrapper,
  TransactionsTableContainer,
  TransactionsWrapper
} from './TransactionsSection.styled'

import {
  fetchLastChunk,
  fixDateOnly,
  formatDate,
  formatMoney,
  isValidArray,
  monthBackDate,
  tr
} from 'mmfintech-commons'
import { actions, formatTransactionDescription, getTransactionStatusName, useCurrencies } from 'mmfintech-backend-api'

export const TransactionsSection = () => {
  const { transactions, transactionsError, transactionsFetching } = useSelector(
    ({ banking: { transactions, transactionsFetching, transactionsError } }: any) => ({
      transactions,
      transactionsError,
      transactionsFetching
    }),
    shallowEqual
  )

  const dispatch = useDispatch()
  const { getCurrencyPrecision } = useCurrencies()

  useEffect(() => {
    dispatch(
      actions.banking.listTransactions({
        from: fixDateOnly(monthBackDate()),
        to: fixDateOnly(new Date()),
        page: 0,
        size: 5,
        sort: 'reqTime-desc,id-desc'
      })
    )
    // eslint-disable-next-line
  }, [])

  return (
    <TransactionsSectionWrapper>
      <TransactionsSectionTitle>
        {tr('FRONTEND.DASHBOARD.TRANSACTIONS.TITLE', 'Recent transactions')}
      </TransactionsSectionTitle>
      <TransactionsWrapper>
        {transactionsFetching ? null : transactionsError ? (
          <ErrorDisplay error={transactionsError} />
        ) : (
          <TransactionsTableContainer>
            {isValidArray(transactions) ? (
              <DataTable.Table scale2='1000px' skipHover={true}>
                <DataTable.Head>
                  <DataTable.Row>
                    <DataTable.HeadCell>{tr('FRONTEND.DASHBOARD.TRANSACTIONS.COLUMN_ID', 'ID')}</DataTable.HeadCell>
                    <DataTable.HeadCell>{tr('FRONTEND.DASHBOARD.TRANSACTIONS.COLUMN_DATE', 'Date')}</DataTable.HeadCell>
                    <DataTable.HeadCell>{tr('FRONTEND.DASHBOARD.TRANSACTIONS.COLUMN_TYPE', 'Type')}</DataTable.HeadCell>
                    <DataTable.HeadCell>
                      {tr('FRONTEND.DASHBOARD.TRANSACTIONS.COLUMN_DESCRIPTION', 'Description')}
                    </DataTable.HeadCell>
                    <DataTable.HeadCell>
                      {tr('FRONTEND.DASHBOARD.TRANSACTIONS.COLUMN_STATUS', 'Status')}
                    </DataTable.HeadCell>
                    <DataTable.HeadCell>
                      {tr('FRONTEND.DASHBOARD.TRANSACTIONS.COLUMN_AMOUNT', 'Amount')}
                    </DataTable.HeadCell>
                  </DataTable.Row>
                </DataTable.Head>

                <DataTable.Body>
                  {transactions.map((data, index) => {
                    const { id, reqTime, amount, currency, direction, status, trnTypeLocalizationKey } = data

                    return (
                      <DataTable.Row key={index} data-test='table-row'>
                        <DataTable.Cell caption={tr('FRONTEND.DASHBOARD.TRANSACTIONS.COLUMN_ID', 'ID')} data-test='id'>
                          {id}
                        </DataTable.Cell>

                        <DataTable.Cell
                          caption={tr('FRONTEND.DASHBOARD.TRANSACTIONS.COLUMN_DATE', 'Date')}
                          className='no-wrap'
                          data-test='date'>
                          {formatDate(reqTime)}
                        </DataTable.Cell>

                        <DataTable.Cell
                          caption={tr('FRONTEND.DASHBOARD.TRANSACTIONS.COLUMN_TYPE', 'Type')}
                          data-test='type'>
                          {tr(trnTypeLocalizationKey, fetchLastChunk(trnTypeLocalizationKey))}
                        </DataTable.Cell>

                        <DataTable.Cell
                          caption={tr('FRONTEND.DASHBOARD.TRANSACTIONS.COLUMN_DESCRIPTION', 'Description')}
                          data-test='description'>
                          {formatTransactionDescription(data)}
                        </DataTable.Cell>

                        <DataTable.Cell
                          caption={tr('FRONTEND.DASHBOARD.TRANSACTIONS.COLUMN_STATUS', 'Status')}
                          className={'text-upper status-' + status.toLowerCase()}
                          data-test='status'>
                          <StatusBanner status={getTransactionStatusName(status)} />
                        </DataTable.Cell>

                        <DataTable.Cell
                          caption={tr('FRONTEND.DASHBOARD.TRANSACTIONS.COLUMN_AMOUNT', 'Amount')}
                          data-test='amount'>
                          {formatMoney(
                            amount * (direction === 'WITHDRAW' ? -1 : 1) || 0,
                            currency,
                            getCurrencyPrecision(currency)
                          )}
                        </DataTable.Cell>
                      </DataTable.Row>
                    )
                  })}
                </DataTable.Body>
              </DataTable.Table>
            ) : (
              <NoElementsMessage
                message={tr('FRONTEND.DASHBOARD.TRANSACTIONS.NONE', "You don't have any transactions yet")}
              />
            )}
          </TransactionsTableContainer>
        )}
      </TransactionsWrapper>
    </TransactionsSectionWrapper>
  )
}
