import cn from 'classnames'

import { CurrencyIcon } from '../../../../components'
import { ExchangeButtonWrap } from './GraphSection.styled'

export const ExchangeButton = ({ node }) => {
  const { fromCurrency, toCurrency, rate, change } = node
  const { changePercentage } = change || {}

  return (
    <ExchangeButtonWrap>
      <div className='left'>
        <CurrencyIcon currency={fromCurrency} size='4rem' />
        <div className='rate'>
          <span className='currency'>
            {fromCurrency}/{toCurrency}
          </span>
          <span className='price'>{rate}</span>
        </div>
      </div>
      <span className={cn('change', { positive: changePercentage > 0, negative: changePercentage < 0 })}>
        {changePercentage}%
      </span>
    </ExchangeButtonWrap>
  )
}
